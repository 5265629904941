/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

$mainfont: "proxima-nova";
$bg-failure: #eb4d4b;
$bg-success: #6ab04c;
$bg-pending: rgba(189,100,0,0.6);

// Theme Colours
$themecolor1: #6a327c; // header
$themeaccentcolor1: #814495;
$themecolor2: #f5f5f5; // background
$themecolor3: #dfe4ea; // pagetitle
$themecolor4: #ffffff; // table-container
$themecolor5: #535c68; // menu

$menudividerlight: #743a87;
$menudividerdark: #572767;
$submenudividerlight: #8f51a4;
$submenudividerdark: #6b377c;

$buttoncolor1: #eb4d4b;
$buttonaccentcolor1: #ff7979;
$buttoncolor2: #f1f2f6;

$titlecolor1: #000000;
$titlecolor2: #227093;

// dark theme
// $themecolor1: #aaa69d;
// $themecolor2: #f7f1e3;



// Global styles
html {height: 100%;}
body {margin: 0px !important; font-family: $mainfont, sans-serif;}
h1 {font-size: 20px;font-weight: 700;font-family: $mainfont, sans-serif;color: $titlecolor1;}

// Body and Container
.mat-drawer-container {background: $themecolor2;background-size: cover;}
.container {height: 100vh;}

// Miscellaneous
.toolbarspacer {flex: 1 1 auto;}
.clear {clear: both;}

// Button Styles
.redbtn {background: $buttoncolor1;color: #fff;}
.redbtn:hover {color: #fff;background: $buttonaccentcolor1;}
.greybtn {background: $buttoncolor2;color: $titlecolor1;}
.greybtn:hover {color: $titlecolor1;}
.filterbtn {background: transparent !important;color: $titlecolor1;}

// Remove browser unwanted styles
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px $themecolor2 inset !important;
}


.vertical-layout, div.defaultLightbox, div.login, div.login > form  { display: flex; flex: 1; flex-direction: column; }

.horizontal-layout, div.main { display: flex; flex: 1; flex-direction: row; }


// Main Header
// ===========
.header {

    & { position: fixed; }

    .mat-toolbar-row {color: #fff;}
    .closebtn {display: block;background: $themeaccentcolor1;color: #fff;font-size: 24px;width: 20px;text-align: center;margin: 10px 60px 10px -16px;height: 30px;padding-top: 8px;transition: width 1s ease;}
    .menubtn {display: block;background: $themeaccentcolor1;color: #fff;font-size: 28px;width: 80px;text-align: center;margin: 0 20px 0 -16px;height: 48px;padding-top: 16px;transition: width 1s ease;}

    //.menu {display: block;color: #fff;font-size: 0.85em;text-transform: uppercase;font-family: $mainfont;padding: 16px 30px 9px;font-weight: 800;text-decoration: none;border-bottom: 5px solid $themecolor1;}
    .active {border-bottom: 7px solid #fff;}
    .logo img {width: 130px;margin-top: 10px;}

}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {background-color: $themecolor1 !important;}
.mat-ink-bar {height: 4px;}
.mat-tab-label-content {text-transform: uppercase;font-family: $mainfont;}
.mat-tab-labels {background: #fff;}



.aside {
    & {font-family: $mainfont;width: 90%;background: #ecf0f1;padding: 0;}

    .header { width: 100%; padding: 15px 2%; margin: 0; background: #FFF;box-shadow: rgba(0,0,0,0.2); text-transform: uppercase; }
    .content { margin: 20px; text-align: justify; }
    .actionBtn { display: flex; justify-content: flex-end; margin: 20px; }

}

.navbar {

    & {display: flex;flex-direction: column;width: 340px;background: #ced6e0; }

    a {
        & { display: block; width: 80%; margin: 0 auto; color: #FFF;
            text-decoration: none; font-size: 1.2em; padding: 5px 10px; border-bottom: 1px solid #3d8fb6;
            border-top: 1px solid #277499; font-family: $mainfont; }

        &:first-of-type { border-top: none; }

        &:last-of-type { border-bottom: none; }

        &:hover { color: #ecf0f1; }
    }
}

/* -- Menu Main -- */
/* -- ========= -- */
.mainmenu {

    & {width: 300px;background: $themecolor1;color: #fff;}

    .menulogo {height: 32px;margin: 6px 0 2px -20px;}

    .container {height: 100%;}
    nav h2 {color: #fff;font-size: 1.5em;text-transform: uppercase;width: 90%;margin: 0 auto;padding: 15px 5%;font-weight: 800;}
    nav h3 {color: #fff;font-size: 1.2em;letter-spacing: 0.1em;text-transform: uppercase;width: 90%;margin: 0 auto;padding: 15px 5%;font-weight: 600;}
    nav a {display: block;width: 90%;margin: 0 auto;color: #fff;text-decoration: none;font-size: 1.1em;font-weight: 300;padding: 15px 5px;background: none;border-bottom: 1px solid $menudividerlight;border-top: 1px solid $menudividerdark;}
    nav .profile {background: $themeaccentcolor1;padding: 20px;text-align: center;font-size: 1.4em;display: flex;align-items: center;}
    nav .profile .profilepic {flex: 0 0 60px;height: 47px;border-radius: 50%;background: $themecolor1;padding-top: 13px;}
    nav .profile .profiledetails {flex: 1 1 auto;font-size: 14px;text-align: left;line-height: 16px;margin-left: 10px;}
    nav .profile .profiledetails span {font-size: 12px;}
    nav .submenu {padding: 5px 5px 5px 20px;font-size: 0.9em;border-top: 1px solid $submenudividerlight;border-bottom: 1px solid $submenudividerdark;}
    nav .sub {background: $themeaccentcolor1;}
    nav a:first-of-type {border-top: none;}
    nav a:last-of-type {border-bottom: none;}
    nav a:hover {color: #ecf0f1;}
    nav a i {display: block;float: left;margin-top: -4px;margin-right: 20px;font-size: 28px;opacity: 0.3;}
    .logo { padding: 15px 10px; width: 90%; border: none; line-height: 0; }
    .logo img { width: 150px; }
}






// main h1 {margin: 10px 0 10px 0;font-size: 2.5em;font-weight: 800;text-transform: uppercase;color: #3c6382;}

// Main container and their styles
// -------------------------------
main {

    & {font-family: $mainfont;}

    // Main toolbar
    .pagetitle {background: transparent !important;width: 100%;}
    .mat-toolbar {background: transparent !important;}
    .mat-toolbar-row h1 {margin: 10px 0 10px 0;font-size: 1.5em;font-weight: 800;text-transform: uppercase;color: $titlecolor1;font-family: $mainfont;}
    .mat-toolbar-row button {font-weight: 800;font-family: $mainfont;text-transform: uppercase;margin-right: 5px;}

    .table-container {display: flex;flex-direction: column;min-width: 300px;background: $themecolor4;border-top: 2px solid $themecolor1;}

    // Mat Table
    // ---------
    .mat-table {font-family: $mainfont;overflow: auto;max-height: 80vh;}
    .mat-header-row {position: sticky;top: 0;background-color: #fff;z-index: 1000;}
    .mat-header-cell {font-size: 1em;color: $themecolor1;font-family: $mainfont;text-transform: uppercase;font-weight: 800;}
    .mat-menu-item {font-family: $mainfont;letter-spacing: 0;font-size: 12px;padding: 0 8px !important;height: auto !important;line-height: 36px !important;margin-bottom: 0;}

    .draft {background: #e55039;color: #fff;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}
    .running {background: #78e08f;color: #fff;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}
    .closed {background: none;color: #78e08f;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}

}

.mat-column-responses {flex: 0 0 160px !important;text-align: center !important;}
.mat-column-status {flex: 0 0 160px !important;text-align: center !important;}
.mat-column-tools {flex: 0 0 80px !important;text-align: right;}



app-lightbox { display: flex; justify-content: center; position: fixed;  width: 100%; height: 100%;
               background: rgba(0,0,0,0.5);  z-index: 10000; font-family: $mainfont; left: 0px; right: 0px; top: 0px; bottom: 0px; }

.lightbox {
    & { position: fixed; text-align: center; padding: 0px; background: #FFF;
        z-index: 10001; border-radius: 9px; min-height: 100px; top: 50%; font-family: $mainfont; transform: translate(0px, -50%); }

    h1 { color: #FFF; background-color: #16577f; font-size: 1.5em; margin: 0px;
         padding: 20px 0px; border-top-right-radius: 7px; border-top-left-radius: 7px; }
}

.defaultLightbox > div {
    padding: 10px;
    font-family: $mainfont;
    &:last-of-type { display: flex;  justify-content: flex-end; }
    > p { text-align: justify; }
}

// Login page
app-login { display: flex; justify-content: center;}

.login {

    & {min-width: 30vw;margin-top: 100px;font-family: $mainfont;background: $themecolor4;padding: 30px;}
    .loginheader {margin: -30px -30px 0 -30px;background: $themecolor1;padding: 20px;text-align: center;}
    .loginheader img {margin: 0 auto;width: 200px;}
    div.actionBtn { display: flex; justify-content: flex-end; }
    .mat-form-field {background: $themecolor4;}
    form {
        input[type="text"] { height: 16px; padding: 0px; border: 0; background: none; border-radius: 0px; }
        input[type="email"] { height: 16px; padding: 0px; border: 0; background: none; border-radius: 0px; }
        input[type="password"] { height: 16px; padding: 0px; border: 0; background: none; border-radius: 0px; }
        input[type="password"]:focus { border: none; outline: none; }
    }

}

// Snackbar container
// ----------------
.mat-snack-bar-container {margin: 20px !important;}
.mat-snack-bar-container-failure {background-color: $bg-failure;}
.mat-snack-bar-container-success {background-color: $bg-success;}
.mat-snack-bar-container-pending {background-color: $bg-pending;color: #fff;}

// ========================= IC CSS ==============================

// =================== Global CSS ===================

// fonts
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700&display=swap');
$ic_raleway_fonts : 'Raleway', sans-serif;

// theme colors
$redcolor: #ee342a;
$bluecolor: #2f3449;

// report page colors
$report_red : #e6534c;
$report_yellow : #ffca26;
$report_green : #429653;

// buttons
.ic-button { font-size: 16px; font-weight: 600; font-family:$ic_raleway_fonts; letter-spacing: normal; border-radius: 4px !important; }
.ic-redbtn { background-color:#FF120D; color:#fff !important;  }



// Form Styles
.mat-form-field {font-family: $ic_raleway_fonts; }

// classes
.frame { max-width: 1440px; margin: 0px auto; width: calc(100% - 140px); }
.frame-small { max-width: 600px; margin: 0px auto; width: calc(100% - 60px); }
.frame-medium { max-width: 815px; margin: 0px auto; width: calc(100% - 60px); }

.forty_parables_cta_btn { margin: 50px auto 0; display: block; width: 160px; background-color: #FF120D; padding: 12px 55px 12px 55px; font-size: 15px; font-weight: 600; text-transform: uppercase; border-radius: 4px 4px 4px 4px; color: #fff; font-family: "Raleway", sans-serif; text-decoration: none; text-align: center; transition: cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.4s; background-image: url('./assets/img/logo-arrow-inverted.png'); box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); background-size: contain; background-position: -100px 100px; background-repeat: no-repeat; }
.forty_parables_cta_btn:hover { transform: scale(1.1); background-position: -15px 15px; box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); }

//  loader
.matspinner { display: flex;justify-content: center;align-items: center;min-height: 100vh;}
.preloader-title { color: #DA4637; font-size: 20px; font-weight: 700; font-family: "Raleway", sans-serif; width: 200%; text-align: center; margin: 20px -50% 0 -50%; }
// =================== Global CSS ===================

// =================== Header ===================

.ic-header {
  .frame{ display: flex; flex-wrap: wrap; justify-content: space-between; height:80px; align-items: center; }

  // header logo
  .header-logo {
    & { line-height: 0px; }
    img { width: 200px; }
  }

  // header menu
  .header-menu {
    a { color:$redcolor; font-family:$ic_raleway_fonts; text-decoration: none; padding: 0px 20px; font-weight: 500; font-size: 15px; }
    a:last-child { padding-right: 0px; }
    .main-item {
      & { position: relative; }
      .material-icons { position: absolute; top: 0px; font-size: 16px; }
     }
     .main-item-submenu { visibility: hidden; opacity: 0; max-height: 0px; position: absolute; left: 0px; top: 50px; background: #333; z-index: 999; transition: max-height 0.2s linear, opacity 0.2s linear, visibility 0.2s linear; min-width: 200px; }
     .main-item-submenu a { transition:0.4s; color:#fff; display: block; text-transform: uppercase; font-size: 12px; padding: 6px 15px; border-bottom: 1px solid #fff; }
     .main-item-submenu a:last-child { border-bottom:0px; }
     .main-item-submenu a:hover { background-color:$redcolor; }
     .main-item:hover .main-item-submenu { visibility: visible; opacity: 1; max-height: 150px; }
  }

  .mobilebutton {display:none; position: relative; z-index: 999; }
  .icon-bar { display: block; height: 3px; margin: 5px 0; transition: .4s; background: $redcolor; width: 25px; }
  .mobilebutton.menu-opened span:first-child {transform: rotate(45deg); transition: .4s;  width: 25px; }
  .mobilebutton.menu-opened span:nth-child(2) { display: none; }
  .mobilebutton.menu-opened span:nth-child(3) { transform: rotate(-45deg); transition: .4s; margin-top: -8px; width: 25px; }

}
// =================== Header ===================

// =================== Footer ===================
.ic-footer {
  & { /*background-color: #444444;*/background-color: #000; padding: 60px 20px 40px 30px; }
  .frame { display: flex; flex-wrap: wrap; max-width: 1140px; }
  .footer-1 {
    & { flex: 0 0 31%; }
    img { width: 171px; margin-bottom: 10px; }
    .footer-1-address { color: #E7E7E7; font-size: 13px; font-weight: 400; font-family:$ic_raleway_fonts; }

  }
  .footer-2  {
    & { flex: 0 0 24%; }
    a { display: block; text-decoration: none; color: #E7E7E7; font-family:$ic_raleway_fonts; font-size: 13px; margin-bottom: 18px; }
  }
  .footer-3  {
    & { flex: 0 0 13%; }
    a { display: block; text-decoration: none; color: #E7E7E7; font-family:$ic_raleway_fonts; font-size: 13px; margin-bottom: 18px; }
  }
  .footer-4 {
    & { flex: 0 0 31%; text-align: right; font-family:$ic_raleway_fonts; color: #E7E7E7; font-size: 13px; }
    a { text-decoration: none; color: #E7E7E7; font-size: 13px; }
    .footer-4-social a i { font-size: 22px; }
    .footer-4-social a { margin-right: 15px; }
    .footer-4-social a:last-child { margin-right:0px; }
    .footer-4-social { margin-bottom: 10px; }
    .phone, .email { display:block; }
    .copyright { margin-top: 8px; }
  }
}
// =================== Footer ===================

// =================== share page ===================

.share-page {
  .share-logo { line-height: 0px; text-align: center; margin-bottom: 50px; }
  .share-logo img { max-width: 195px; }
  .share-wrapper { margin: 40px 0px; }
  .share-title { color: #4a4a4a; font-size: 48px; font-weight: 700; font-family:$ic_raleway_fonts; line-height: normal; text-align: center; }
  .share-subtitle { font-family: $ic_raleway_fonts; color: #202020; font-size: 21px; font-weight: 500; text-align: center; margin: 10px 0px 30px; }

  // form
  .form-title { font-family:$ic_raleway_fonts; margin: 5px 0px; color: #202020; font-size: 14px; font-weight: 700; }
  .form-fields-wrapper { display: flex; flex-wrap: wrap; margin: 14px 0px 22px; }
  .form-fields-wrapper .material-icons.delete { font-size: 20px; width: 32px; display: flex; align-items: center; justify-content: center; font-weight: 600; margin-left: 10px; border-radius: 4px; cursor: pointer; transition:0.4s; color: #d5d5d5; }
  .form-fields-wrapper .material-icons.delete.field-disabled { opacity:0.5; cursor: default; color: #d5d5d5 !important; }
  .form-fields-wrapper .material-icons.delete:hover { color: #ee342a; }
  .form-fields-wrapper .add-button { width: 75px; height: 35px; margin-left: 10px; padding: 0 10px; }
  .form-fields-wrapper .add-button .material-icons { font-size: 20px; }
  .form-fields-wrapper .add-button .mat-button-wrapper { display: flex; align-items: center; justify-content: space-between; }

  .first-name { width: 175px; }
  .email { width: 295px; }
  .first-name .mat-form-field-outline-end,  .email .mat-form-field-outline-start { border-radius: 0 0px 0px 0; }
  .first-name .mat-form-field-outline-thick .mat-form-field-outline-end { border-radius: 0 5px 5px 0; }
  .email .mat-form-field-outline-thick .mat-form-field-outline-start { border-radius: 5px 0 0 5px; }
  .share-result { margin: 30px auto; text-align: center; }
  .share-btn-block { margin-top: 15px; }
  .share-button { width: 250px; height: 44px; margin-bottom: 50px; transition: 0.4s; }
  .share-button.mat-button-disabled { background: #bdc3c7; color: #95a5a6; }
  .share-button.circle-btn { width: 55px; height: 55px; border-radius: 100% !important; min-width: 55px; padding: 0px; }

  // mat input
  .mat-form-field-wrapper { margin: 0 0; padding-bottom: 0px; }
  .mat-form-field-infix { padding: 2px 0 8px 0; }
  .mat-form-field-flex { padding: 0 10px 0 20px !important; }
  input.mat-input-element { font-family:$ic_raleway_fonts; font-size: 14px; color: #202020; font-weight: 600; }
  input.mat-input-element:focus::placeholder { color: transparent; }
  .mat-form-field-wrapper { margin: 0 0 !important; }

  // mat checkbox
  .mat-checkbox-layout { width: 100%; margin-bottom: 0px; display: flex; align-items: center; flex-wrap: wrap; justify-content: center; }
  .mat-checkbox-inner-container { margin-left: 0px; }
  span.mat-checkbox-label { font-family: $ic_raleway_fonts; color: #202020; font-size: 14px; font-weight: 500; }
  .mat-checkbox-inner-container { height: 20px; width: 20px; }
  .mat-checkbox-frame { border-color: $redcolor; }
  .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element { background: $redcolor; }
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background { background-color: $redcolor; }
  .mat-error { margin-top: 18px; }

}

.share-page.share-confirmation-page {
  .share-title { font-size: 36px; text-align: center; }
  .confirmation-note { font-family: $ic_raleway_fonts; color: #202020; font-size: 21px; font-weight: 500; text-align: center; margin: 30px 0px 45px; }
  .back-to-results { text-align: center; font-size: 15px; font-weight: 600; text-transform: uppercase; padding-bottom: 40px; }
  .back-to-results a { color: #202020; text-decoration: none; border-bottom: 3px solid $redcolor; }
  .confirmation-tick { text-align: center; line-height: 0; margin-bottom: 30px; }
  // svg
  .tick-svg { width: 56px; height: 56px; border-radius: 8px; display: inline-block; stroke-width: 5; stroke: #fff; stroke-miterlimit: 10; box-shadow: inset 0px 0px 0px $redcolor; animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both; }
  .tick-svg-check { transform-origin: 50% 50%; stroke-dasharray: 48; stroke-dashoffset: 48; animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards; }
  @keyframes stroke {
    100% { stroke-dashoffset: 0; }
  }
  @keyframes scale {
    0%, 100% { transform: none; }
    50% { transform: scale3d(1.1, 1.1, 1); }
  }
  @keyframes fill {
    100% { box-shadow: inset 0px 0px 0px 30px $redcolor; }
  }
}

// lightbox
.lightbox { width:350px; }
.lightbox h1 { background-color: $redcolor; font-family: $ic_raleway_fonts; font-weight: 600; font-size: 16px; }
.lightbox .lightboxcontent { font-family: $ic_raleway_fonts; }
.lightbox .lightboxfooter button { font-family: $ic_raleway_fonts; font-size: 15px; }

// =================== share page ===================

// =================== report page ===================
.report-page {
  & { background-color:$bluecolor; padding: 40px 0px; }

  // tools
  .tools {
    & { display: flex; color: #fff; justify-content: flex-end; margin-right: 40px; }
    .download { display: none; align-items: center; padding: 2px 20px 2px 0px; }
    .print { display: flex; align-items: center; padding: 2px 0px 2px 20px; }
    span.material-icons { font-size: 24px; margin-right: 8px; cursor: pointer; }
    a { color: #fff; text-decoration: underline; font-family:$ic_raleway_fonts; font-weight: 600; font-size: 16px; cursor: pointer; }
  }
  .report-page-frame { margin-top: -15px; }
  // testimonial
  .testimonial {
    & { margin: 110px 0px 90px; display: flex; flex-wrap: wrap; }
    .quotation-open { width: 1em; height: 1em; position: relative; display: block; margin: -20px 0 10px -10px; fill: #DA4637; }
    .quotation-close { width: 1em; height: 1em; position: relative; display: block; float: right; margin: 20px 5px 0 0; fill: #DA4637; }
    .testimonial-left {
      & { flex: 0 0 190px; position: relative; margin-right: 30px; }
      img { width: 164px; border-radius: 15px; position: relative; }
      .image-bg { width: 164px; height: 164px; background-color: #FF120D; border-radius: 15px; position: absolute; right: 0px; top: -20px; }
    }
    .testimonial-right {
      & { flex: 0 0 calc(100% - 220px); }
      .testimonial-text {
        & { color: #fff; font-size: 20px; font-weight: 400; line-height: 1.7em; font-family:$ic_raleway_fonts; position: relative; margin-left: 30px; }
        span { color: $redcolor; font-size: 72px; font-weight: 900; font-family: $ic_raleway_fonts; }
        .inverted-comma-start { position: absolute; top: -25px; margin-left: -30px; }
        .inverted-comma-end { position: absolute; right: 0px; margin-top: 25px; }
      }
      .testimonial-name { color: #fff; font-size: 14px; font-family: $ic_raleway_fonts; font-weight: 600; margin-top: 40px; line-height: normal; margin-left: 30px; }
      .testimonial-profile { color: #fff; font-size: 14px; font-family: $ic_raleway_fonts; font-weight: normal; margin-top: 10px; line-height: normal; margin-left: 30px; }
    }
  }

  // title
  .title { font-family: $ic_raleway_fonts; color: #fff; font-size: 36px; font-weight: 600; text-align: center; }
  // content
  .block-content { font-family: $ic_raleway_fonts; color: #4a4a4a; font-size: 16px; line-height: normal; margin: 15px 0px 30px; }
  // bars
  .bars { width: 100%; height: 20px; display: flex; flex-wrap: wrap; position: relative; }
  .low { background-color: $report_red; height: 100%; flex: 0 0 16.6%; }
  .low-text { position: absolute; left: 15.6%;top: 30px; color: #fff; font-size: 17px; }
  .medium { background-color: $report_yellow; height: 100%; flex: 0 0 33.4%; }
  .medium-text { position: absolute; left: 49%;top: 30px; color: #fff; font-size: 17px; }
  .high { background-color: $report_green; height: 100%; flex: 0 0 50%; }
  .high-text { position: absolute; left: 98%;top: 30px; color: #fff; font-size: 17px; }
  .progress-bar { margin: 30px 80px 60px; }
  .progress { color: #fff; font-size: 36px; font-weight: 600; font-family: $ic_raleway_fonts; }
  .bar { height: 28px; width: 3px; background-color: #fff; position: absolute; top: -4px; }
  
  // summary
  .report-summary { text-align: center; color: #fff; font-size: 18px; font-family: $ic_raleway_fonts; margin: 0px 80px 40px; padding: 20px 15px 30px; background-color: #222535; }
  // block
  .report-block { background-color: #FFF; border-radius: 15px; padding: 20px 40px; }
  .block-title { color: #4a4a4a; font-size: 20px; font-weight: 700; font-family: $ic_raleway_fonts; display: flex; flex-wrap: wrap; justify-content: space-between; }
  .block-title .material-icons { color: $redcolor; font-size: 22px; margin-right: 10px; cursor:pointer; }
  .block-title .material-icons:last-child { margin-right: 0px; }
  // report-expansion
  .report-expansion {
    & { margin: 10px 0px 15px; }
    .green-header {background-color: $report_green !important; }
    .yellow-header {background-color: $report_yellow !important; }
    .red-header {background-color: $report_red !important; }
  }
  // mat expansion
  .mat-expansion-panel:not([class*=mat-elevation-z]) { box-shadow: none; }
  .mat-expansion-panel-header { height: auto; padding: 6px 15px; border-radius: 3px; }
  .mat-content { font-size: 18px; font-weight: 600; font-family: $ic_raleway_fonts; align-items: center; }
  .mat-expansion-panel-header-title { color: #fff; }
  .mat-expansion-panel-header-description { flex-grow: 0; margin-right: 0px; color: #fff; }
  .mat-expansion-panel-header-description .mat-icon { font-weight: 600; }
  .mat-expansion-indicator::after { color: #fff; }
  .mat-expansion-panel-body { padding: 10px 0px 0px; }
  .mat-expansion-panel-body p { color: #4a4a4a; font-size: 16px; line-height: normal; font-family: $ic_raleway_fonts; padding: 5px 0px 15px; border-bottom: solid 1px #ececec; margin: 0px; }
  .mat-expansion-panel-body p:last-child { border-bottom: 0px; }
  .mat-expansion-panel { margin-bottom: 8px; }
  .mat-expansion-panel-spacing { margin: 0px 0; }
  .mat-expansion-panel.downloading .mat-expansion-panel-content { height: auto !important; visibility: visible !important; }

  // share-with-team
  .share-with-team {
    &.share-with-team-2 { margin-bottom:30px; }
    & { margin: 50px 0px 100px; text-align: center; background-color: #222535; padding: 60px 15px 40px; }
    .share-with-team-title { font-family: $ic_raleway_fonts; color: #fff; font-size: 20px; font-weight: 600; margin-bottom: 30px; }
    .share-with-team-button { height: 44px; width: 250px; }
  }
  // next steps
  .next-steps-block {
    & { margin-bottom: 30px; }
    &:last-child { margin-bottom: 0px; }
    .for-you-without-tab, .for-teams-without-tab {
      & { display:none; }
      .for-you-without-tab-title, .for-teams-without-tab-title { font-family: $ic_raleway_fonts; color: #4a4a4a; text-transform: uppercase; font-size: 18px; font-weight: 700; }
    }
    .next-step-title { font-family: $ic_raleway_fonts; color: $redcolor; font-size: 16px; text-transform: uppercase; font-weight: 700; }
    .next-step-wrapper { display: flex; align-items: flex-start; margin: 10px 0px 25px; }
    .next-step-wrapper-left { margin-right: 10px; font-size: 16px; font-family:$ic_raleway_fonts; background-color: $redcolor; width: 25px; height: 25px; display: flex; border-radius: 100%; align-items: center; justify-content: center; color: #fff; font-weight: 700; flex: 0 0 25px; }
    .next-step-wrapper-left span { margin-top: -2px; }
    .next-step-wrapper-title { font-family: $ic_raleway_fonts; font-weight: 700; color: #2f3449; font-size: 16px; }
    .next-step-wrapper-content { font-family: $ic_raleway_fonts; font-weight: 500; color: #2f3449; font-size: 16px; }
    .next-step-wrapper-content a { color: $redcolor; }
    .next-step-wrapper-content ul { margin-bottom: 0px; list-style: disc; padding-left: 30px; }
    .next-step-wrapper-content ul li { margin-bottom: 0px; }

    .mat-ink-bar { display: none; }
    .mat-tab-label { width: 50%; border-radius: 5px; font-size: 16px; font-weight: 700; background-color: #d8d8d8; opacity: 1; height: 52px; margin: 0px 4px; }
    .mat-tab-label:first-child { margin-left:0px; }
    .mat-tab-label:last-child { margin-right:0px; }
    .mat-tab-label.mat-tab-label-active { background-color: $redcolor; color: #fff; text-transform: uppercase; border-radius: 5px 5px 0px 0px; height: 57px; }
    .mat-tab-label.mat-tab-label-active .mat-tab-label-content { color:#fff; }
    .mat-tab-label .mat-tab-label-content { font-family: $ic_raleway_fonts; color: #4a4a4a;  }
    .mat-tab-header { border-bottom:0px; }
    .mat-tab-body-wrapper { border: 10px solid $redcolor; border-radius: 0px 0px 5px 5px; background-color: $redcolor; }
    .mat-tab-body { border-radius: 5px; background-color: #fff; padding: 10px 20px; }

  }
}
// =================== report page ===================

// =================== churchos page ===================
.screen-block {
  & { min-height: calc(100vh - 100px); align-items: center; display: none; }
  &.visible { display: flex; }
}

.churchos-page {
  & { font-family: $ic_raleway_fonts; }

  // first screen
  .churchos-first-screen {
    & { padding: 50px 0px; }
    // logo
    .churchos-assessment-logo-wrapper {
      & { display: flex; flex-wrap: wrap; align-items: center; justify-content: center; }
      img { width: 43px; height: 43px; margin-right: 10px; }
      .churchos-assessment-logo {
        & { color: #444444; font-size: 18px; font-weight: 500; letter-spacing: 0.3px; text-align: center; line-height: 20px; }
        .churchos-title { font-weight: 700; font-size: 25px; }
      }
    }

    // image
    .churchos-flag-image { text-align: center; }

    // content
    .churchos-content {
      & { text-align: center;  }
      .churchos-heading { color: #333333; font-size: 36px; font-weight: 600; line-height: 1.2em; margin-top: 15px; }
      .churchos-subheading { color: #333333; font-size: 20px; font-weight: 400; margin-top: 25px; }
      .get-started { margin-top: 50px; }
    }

  }

  // second screen
  .churchos-second-screen {
    & { background-color:#4A4E5E; padding: 50px 0px; text-align: center; color: #fff; }
    .churchos-ss-title { text-transform: uppercase; font-size: 16px; font-weight: 700; margin-bottom: 10px; }
    .churchos-ss-content { font-size: 16px; font-weight: 600; margin-bottom: 10px; }
    .churchos-ss-info-wrapper {
      & { margin-top: 35px; }
      .churchos-ss-info-block {
        &:nth-child(1) .churchos-ss-info-left { background-color: #e6534c; }
        &:nth-child(2) .churchos-ss-info-left { background-color: #ffca26; }
        &:nth-child(3) .churchos-ss-info-left { background-color: #429653; }
        & { text-align: left; display: flex; flex-wrap: wrap; align-items: center; margin-bottom: 35px; }
        .churchos-ss-info-left { flex: 0 0 42px; width: 42px; height: 42px; border-radius: 5px; margin-right: 30px; }
        .churchos-ss-info-right { flex: 0 0 calc(100% - 72px); font-size: 16px; font-weight: 500; }
      }
    }
  }

  // third screen
  .churchos-third-screen {
    & { padding: 50px 0px; }

    // counter
    .churchos-ts-counter {
      & { text-align: center; margin-bottom: 18px; }
      span { border: 1px solid #db493a; width: 42px; height: 42px; display: inline-flex; align-items: center; justify-content: center; border-radius: 5px; font-size: 20px; color: #333333; }
    }

    // bar
    .churchos-ts-progress-bar {
      & { max-width: 300px; margin: 0 auto; }
      .mat-progress-bar { margin: 0 auto; height: 2px; }
      .mat-progress-bar-fill::after { background-color: #db493a; }
      .mat-progress-bar-buffer { background-color: #e8e8e8; }
      .churchos-percentage span { transition: 0.4s; }
    }

    // question
    .churchos-ts-question-wrapper {
      & { max-width: 400px; margin: 20px auto 0px; text-align: center; font-size: 16px; font-weight: 600; min-height: 150px; }
      b { color: #db493a; font-weight: 500; margin-bottom: 5px; display: block; text-transform: uppercase; }
    }

    // radio
    .churchos-ts-radio {
      .mat-radio-group { width: 100%; display: flex; flex-wrap: wrap; }
      .mat-radio-button { flex: 0 0 calc(33.33% - 40px); padding: 10px 10px 10px 10px; background: #f5f5f5; margin: 10px; border-radius: 5px; }
      .mat-radio-button.mat-radio-checked, .mat-radio-button:hover {
        &{ background: #5e6170; }
        .mat-radio-label-content { color: #ffffff; }
      }
      .mat-radio-button.mat-radio-checked .churchos-ts-radio-left .material-icons { display:block; }
      .mat-radio-label { margin-bottom: 0px; }
      .mat-radio-container { display: none; }
      .mat-radio-label-content { display: flex; flex-wrap: wrap; align-items: center; padding: 0; }
      .churchos-ts-radio-left { flex: 0 0 42px; width: 42px; height: 42px; border-radius: 5px; margin-right: 8px; display: flex; align-items: center; justify-content: center; }
      .churchos-ts-radio-left .material-icons { display:none; font-size: 28px; }
      .churchos-ts-radio-right { flex: 0 0 calc(100% - 50px); font-family: $ic_raleway_fonts; font-weight: 400; font-size: 14px; }
      .mat-radio-button:nth-child(1) .churchos-ts-radio-left { background-color: #e6534c; }
      .mat-radio-button:nth-child(2) .churchos-ts-radio-left { background-color: #ffca26; }
      .mat-radio-button:nth-child(3) .churchos-ts-radio-left { background-color: #429653; }
     }

    //  error
     .churchos-ts-error { font-size: 14px; font-weight: 400; text-align: center; color: #f2322b; margin-top: 5px; }

    //  previous-next-btn
     .previous-next-btn, .edit-your-response {
      & { display: flex; justify-content: space-between; flex-wrap: wrap; margin-top: 10px; }
      a { color: #333; display: flex; align-items: center; font-size: 14px; font-weight: 400; cursor: pointer; }
      a.next-btn { color: #DA4637; font-weight: 700; }
     }

    //  title
    .churchos-ts-title { color: #db493a; font-weight: 500; margin-bottom: 10px; text-align:center; font-size: 22px; }

    // form
    .churchos-ts-form-wrapper {
      & { max-width: 500px; margin: 25px auto 0px; }
      .churchos-ts-form-content { text-align: center; font-size: 16px; font-weight: 600; margin-bottom: 20px; }
      .unlock-your-report {
        & { text-align: center; }
        button { height: 44px; width: 250px; }
      }
      .churchos-ts-main-form {
        .churchos-ts-form-half {
          .mat-form-field { width: calc(50% - 10px); }
          .mat-form-field:nth-child(1) { margin-right: 10px; }
          .mat-form-field:nth-child(2) { margin-left: 10px; }
        }
        .churchos-ts-form-full {
          .mat-form-field { width: 100%; }
        }
        .mat-form-field {
          .mat-form-field-infix { padding: 10px 0px 12px 0px; border-top-width: 4px ; }
          .mat-form-field-label-wrapper { top: -12px; }
          &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, &.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label { transform: translateY(-1.2em) scale(0.75); }
        }
      }
    }
    .edit-your-response { margin-top: 0px; }

  }

}

// =================== churchos page ===================

// print
@media print {

  @page { margin: 2cm; }

  .report-page .title,
  .report-page .progress,
  .report-page .report-summary,
  .report-page .mat-expansion-panel-header-title,
  .report-page .mat-expansion-panel-body p,
  .report-page .share-with-team .share-with-team-title
  // .report-page .share-with-team .share-with-team-button span
  { color: #4a4a4a; }

  .report-page .report-summary br { display:none; }

  .report-page .low,
  .report-page .medium,
  .report-page .high,
  .report-page .bar,
  .report-page .share-with-team .share-with-team-button,
  .report-page .next-steps-block .next-step-wrapper-left span
  {-webkit-print-color-adjust: exact;}

  .mat-expansion-panel-content { height: auto !important; visibility: visible !important; }
  .report-page .mat-expansion-panel-header-description .mat-icon { display:none; }

  .report-page .share-with-team { display: none !important; }
  .report-page .share-with-team { margin: 0px 0px 0px; padding: 0px 15px 0px; }
  .report-page .testimonial {display: none; }
  .ic-footer { display: none; }
  .report-page .tools .print { display: none; }

  .report-page .next-steps-block .for-you-without-tab, .report-page .next-steps-block .for-teams-without-tab {
    & { display:block; }
  }
  .report-page .next-steps-block .mat-tab-group { display: none; }
  #sharelink { display: none; }

  [data-aos] {
    display: block !important;
    opacity: 1 !important;
    transform: translate(0) scale(1) !important;
  }

}

@media only screen and (max-height: 640px) {
  .churchos-page .churchos-first-screen {
    & { padding: 40px 0px; }
  }
}

/*@media only screen and (max-height: 620px) {
  .churchos-page .churchos-first-screen {
    & { padding: 20px 0px; }
  }
}*/


@media only screen and (max-height: 532px) {
  .churchos-page .churchos-first-screen {
    & { padding: 20px 0px 0px; }
  }
}

@media only screen and (max-height: 532px) {
  .churchos-page .churchos-first-screen {
    & { padding: 20px 0px 0px; }
  }
}

@media only screen and (max-width: 1199px) {
  .report-page .report-page-frame { margin-top: 0px; }
}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 991px) {
  .ic-footer .footer-1 { flex: 0 0 100%; margin-bottom: 20px; }
  .ic-footer .footer-2, .ic-footer .footer-3 { flex: 0 0 50%; }
  .ic-footer .footer-4 { flex: 0 0 100%; text-align: left; }

  // church os page
  .churchos-page .churchos-first-screen .churchos-content .churchos-heading { font-size: 28px; }
  .churchos-page .churchos-first-screen .churchos-content .churchos-subheading { font-size: 18px; margin-top: 20px; }
  .churchos-page .churchos-third-screen .churchos-ts-radio .mat-radio-button { flex: 0 0 calc(50% - 40px); }
}

@media only screen and (max-width: 767px) {
  // global
  .frame { width: calc(100% - 60px); }
  // share page
  .share-page .share-logo { margin-bottom: 40px; }
  .share-page .share-title { font-size: 36px; }
  .share-page .share-button { margin-bottom: 40px; }
  .share-page .share-title br { display:none; }
  .share-page .email { width: auto; }
  // header
  .ic-header .mobilebutton { display: block; }
  .ic-header .header-logo img { width: 150px; }
  .ic-header .header-menu { margin-left: auto; left: 0; width: calc(100% - 0px); position: fixed; top: 0; min-height: 100vh; padding: 80px 0px 40px; visibility: hidden; opacity: 0;height: 0; transition: .4s; flex-direction: column; align-items: flex-start; z-index: 999; }
  .ic-header .header-menu.menu-opened { visibility: visible; opacity: 1; height: auto; }
  .ic-header .header-menu a { padding: 10px 15px !important; width: calc(100% - 30px); text-align: left; font-size: 14px; -webkit-tap-highlight-color: transparent; text-transform: capitalize; display: block; background-color: #333333; color: #fff; border-bottom: 1px solid #fff; }
  .ic-header .header-menu a:last-child { border-bottom:0px; }
  .ic-header .header-menu .main-item .material-icons { top: 12px; font-size: 20px; right: 10px; }
  .ic-header .header-menu .main-item-submenu { visibility: visible; opacity: 1; max-height: 100%; position: static; min-width: 100%; margin: 0px -15px; }
  .ic-header .header-menu .main-item-submenu a { padding: 10px 15px !important; width: 100%; font-size: 14px; text-transform: capitalize; }
  .ic-header .header-menu .main-item-submenu a:first-child { margin-top: 10px; border-top: 1px solid #fff; }
  .ic-header .header-menu .main-item-opened { padding-bottom: 0px !important; }
  .ic-header .header-menu .main-item-opened .material-icons { transform: rotate(180deg); }

  // report page
  .report-page .title { font-size: 28px; }
  .report-page .progress { font-size: 32px; position: relative; right: 35px; }
  .report-page .progress-bar { margin: 30px 40px 20px; }
  .report-page .report-summary { margin: 0px 40px 40px; }
  .report-page .report-summary br { display:none; }
  .report-page .share-with-team { margin: 40px 0px; }
  .report-page .share-with-team .share-with-team-button { height: 45px; width: 220px; }
  .report-page .report-block { padding: 20px 30px; }
  .report-page .report-page-frame { margin-top: 30px; }
  .report-page .testimonial .testimonial-right { flex: 0 0 100%; margin-top: 40px; }
  .report-page .next-steps-block .mat-tab-label { font-size: 14px; padding: 0 10px; }

  // church os page
  .churchos-page .churchos-first-screen .churchos-content .churchos-heading { font-size: 24px; }
  .churchos-page .churchos-first-screen .churchos-content .churchos-subheading { font-size: 16px; margin-top: 15px; }

}

@media only screen and (max-width: 600px) {

  // share page
  .share-page .share-title { font-size: 28px; }
  .share-page .first-name { width: 150px; }
  .share-page .email { width: calc(100% - 277px); }

  // report page
  .report-page .title { font-size: 26px; }
  .report-page .progress-bar { margin: 20px 30px 20px; }
  .report-page .report-summary { margin: 0px 30px 30px; }
  .report-page .block-title { font-size: 16px; }
  .report-page .mat-content { font-size: 16px;}
  .report-page .progress {font-size: 24px; }
  .report-page .tools a { font-size: 14px; }
  .report-page .tools span.material-icons { font-size: 20px; }
  .report-page .tools .download { padding: 2px 15px 2px 0px; }
  .report-page .tools .print { padding-left: 2px 0px 2px 15px; }
  .report-page .testimonial .testimonial-right .testimonial-text { font-size: 17px; margin-left: 0px; line-height: 28px; }
  .report-page .next-steps-block .mat-tab-label { padding: 0 24px; }
  .report-page .next-steps-block .mat-tab-body { padding: 10px 15px; }
  .report-page .report-block { border-radius: 5px; }
  .report-page .testimonial .testimonial-left img { border-radius: 5px; }
  .report-page .testimonial .testimonial-left .image-bg { border-radius: 5px; }
  .report-page .report-block { margin: 0px -30px; }

  // church os page
  .churchos-page .churchos-first-screen .churchos-flag-image img { width: 100%; }
  .churchos-page .churchos-first-screen .churchos-content .churchos-heading { font-size: 20px; }
  .churchos-page .churchos-first-screen .churchos-content .churchos-subheading { font-size: 14px; }
  .churchos-page .churchos-second-screen .churchos-ss-title { font-size: 15px; }
  .churchos-page .churchos-second-screen .churchos-ss-content { font-size: 15px; }
  .churchos-page .churchos-second-screen .churchos-ss-info-wrapper { margin-top: 25px; }
  .churchos-page .churchos-second-screen .churchos-ss-info-wrapper .churchos-ss-info-block { margin-bottom: 20px; }
  .churchos-page .churchos-second-screen .churchos-ss-info-wrapper .churchos-ss-info-block .churchos-ss-info-right { font-size: 15px; }
  .churchos-page .churchos-third-screen .churchos-ts-counter { margin-bottom: 15px; }
  .churchos-page .churchos-third-screen .churchos-ts-question-wrapper { margin: 10px auto 10px; font-size: 15px; }
  .churchos-page .churchos-third-screen .churchos-ts-radio .mat-radio-button { flex: 0 0 calc(100% - 20px); margin: 5px 0px;; }
  .churchos-page .churchos-third-screen .edit-your-response { margin-top: 15px; }
}

@media only screen and (max-width: 480px) {
  // share page
  .share-page .share-wrapper { margin: 30px 0px; }
  .share-page .share-logo { margin-bottom: 30px; }
  .share-page .share-logo img { width: 150px; }
  .share-page .share-title { font-size: 29px !important; }
  .share-page .first-name { width: 100%; margin-bottom: 20px; }
  .share-page .share-result { margin-top: 20px; }
  .share-page .share-button { margin-bottom: 30px; height: 40px; }
  .share-page .email { width: calc(100% - 127px); }

  // report page
  .report-page .title { font-size: 22px; }
  .report-page .progress-bar { margin: 20px 0px 20px; }
  .report-page .report-summary { margin: 0px 0px 30px; }
  .report-page .block-title { flex-wrap: nowrap; justify-content: space-between; }
  .report-page .block-title .material-icons { font-size: 20px; }
  .report-page .block-title .tools { display: flex; }
  .report-page .report-block { padding: 20px 20px; }
  .report-page .next-steps-block .next-step-wrapper-content ul { padding-left: 20px; }
  .report-page .progress { right: 40px; }
  .report-page .tools .download { display: flex; }
  .report-page .tools .print { display: none; }

  .confirmation-note { margin: 15px 0px 20px !important; }
  // .back-to-results { text-align: center; font-size: 15px; font-weight: 600; text-transform: uppercase; padding-bottom: 40px; }
  .ic-footer .footer-2, .ic-footer .footer-3 { flex: 0 0 100%; }
  .ic-footer { padding: 50px 0px; }

  // church os page
  .churchos-page .churchos-first-screen .churchos-content .churchos-heading br,
  .churchos-page .churchos-first-screen .churchos-content .churchos-subheading br,
  .churchos-page .churchos-third-screen .churchos-ts-form-wrapper .churchos-ts-form-content br { display:none; }
  .churchos-page .churchos-second-screen .churchos-ss-content { font-size: 14px; }
  .churchos-page .churchos-second-screen .churchos-ss-info-wrapper .churchos-ss-info-block .churchos-ss-info-right { font-size: 12px; }
  .churchos-page .churchos-second-screen .churchos-ss-info-wrapper .churchos-ss-info-block .churchos-ss-info-left { margin-right: 15px; }
  .churchos-page .churchos-second-screen .churchos-ss-info-wrapper .churchos-ss-info-block .churchos-ss-info-right { flex: 0 0 calc(100% - 57px); }
  .churchos-page .churchos-third-screen .churchos-ts-form-wrapper .churchos-ts-main-form .churchos-ts-form-half { margin-bottom: 10px; }
}

@media only screen and (max-width: 375px) {
  .report-page .progress { font-size: 22px; }
  .lightbox { width: calc(100% - 30px); }

  // church os page
  .churchos-page .churchos-first-screen .churchos-content .churchos-heading { font-size: 18px; }
  .churchos-page .churchos-first-screen .churchos-content .churchos-subheading { font-size: 12px; }
  .churchos-page .churchos-third-screen .churchos-ts-question-wrapper { font-size: 13px; }
  .churchos-page .churchos-third-screen .churchos-ts-question-wrapper b { font-size: 14px; }
  .churchos-page .churchos-third-screen .churchos-ts-radio .churchos-ts-radio-right { font-size: 12px; }
  .churchos-page .churchos-third-screen .churchos-ts-error { font-size: 12px; }
  .forty_parables_cta_btn { font-size: 12px; }
  .churchos-page .churchos-third-screen .churchos-ts-form-wrapper .churchos-ts-form-content { font-size: 14px; }
  .churchos-page .churchos-third-screen .churchos-ts-title { font-size: 18px; }

  .report-page .testimonial .testimonial-right .testimonial-name { margin-left: 0; }
  .report-page .testimonial .testimonial-right .testimonial-profile { margin-left: 0; }

}

@media only screen and (max-width: 320px) {

}

svg g#Group-3 { opacity: 1; animation: 4s moveArrow linear infinite; transform: translate(167.175950px, 91.322017px) rotate(28deg); }

@keyframes moveArrow {
  /* 0% {transform: translate(84.175950px, 119.322017px) rotate(23deg); } */
  0% {transform: translate(51.175950px, 132.322017px) rotate(22deg); opacity: 0; }
  1% {transform: translate(51.175950px, 132.322017px) rotate(22deg); opacity: 1; }
  11% {transform: translate(167.175950px, 91.322017px) rotate(28deg); opacity: 1; }
  19% {transform: translate(251.175950px, 71.8px) rotate(35deg); opacity: 1; }
  28% {transform: translate(335.175950px, 57.622017px) rotate(38deg); opacity: 1; }
  37% {transform: translate(419.175950px, 49.322017px) rotate(40deg); opacity: 1; }
  46% {transform: translate(503.175950px, 47.322017px) rotate(46deg); opacity: 1; }
  47% {transform: translate(503.175950px, 47.322017px) rotate(46deg); opacity: 0; }
  // 47% {transform: translate(503.175950px, 47.322017px) rotate(46deg); opacity: 0; }
  100% {transform: translate(51.175950px, 132.322017px) rotate(22deg); opacity: 0; }
}

.forty_parables_arrow_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 4px;
  position: relative;
  // transform: translate(-20px, 20px);
  // animation: moveToDot 3s 1;
  // animation-fill-mode: forwards;
  // animation-delay: 0.5s;
}

.forty_parables_arrow_container .item .animatered {
  animation-play-state: paused;
}

.item { aspect-ratio: 1.1 / 1; position: relative; transition: all .2s ease-in-out; }
/* .item:hover { transform: scale(1.2); } */
.item .square {position: absolute; opacity: 0; left: 5%; top: 5%; width: 90%; height: 90%; border-radius: 3px; background: #d8d8d8; animation: enlarge 4s 1; animation-iteration-count: infinite; }
.item .arrow { background: #f2f2f2; }
.item .reddot { background: #ee3126; opacity: 1; }

.animate1 { animation-delay: 1.1s !important; }
.animate2 { animation-delay: 1.2s !important; }
.animate3 { animation-delay: 1.3s !important; }
.animate4 { animation-delay: 1.4s !important; }
.animate5 { animation-delay: 1.5s !important; }
.animate6 { animation-delay: 1.6s !important; }
.animate7 { animation-delay: 1.7s !important; }
.animate8 { animation-delay: 1.8s !important; }
.animate9 { animation-delay: 1.9s !important; }
.animate10 { animation-delay: 2s !important; }
.animate11 { animation-delay: 2.1s !important; }
.animate12 { animation-delay: 2.2s !important; }
.animate13 { animation-delay: 2.3s !important; }
.animate14 { animation-delay: 2.4s !important; }
.animate15 { animation-delay: 2.5s !important; }
.animate16 { animation-delay: 2.6s !important; }
.animate17 { animation-delay: 2.7s !important; }
.animate18 { animation-delay: 2.8s !important; }
.animate19 { animation-delay: 2.9s !important; }

.animatered { animation: reddot 3s 1 !important;animation-fill-mode: forwards !important; animation-delay: 2s !important; animation-play-state: paused; }

.item .arrow { animation-delay: 0.2s !important; }

@keyframes enlarge {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  15% {
    transform: scale(0.6);
    opacity: 0.2;
  }
  35% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
